import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';
import UserLogin from '../components/Login.vue';
import Home from '../views/Home.vue';
import UserManage from '../components/System/UserManage.vue';
import ChangePassword from '../components/System/ChangePassword.vue';
import CreateChannel from '../components/Data/CreateChannel.vue';
import CreateCourse from '../components/Data/CreateCourse.vue';
import OrderData from '../components/Data/OrderData.vue';
import TransformData from '../components/Data/TransformData.vue';
import ExpCourse from '../components/Data/ExpCourse.vue';

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: UserLogin },
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: 'user-manage',
        component: UserManage,
        meta: { requiresAdmin: true }
      },
      { path: 'change-password', component: ChangePassword },
      {
        path: 'create-channel',
        component: CreateChannel,
        meta: { requiresAdmin: true }
      },
      {
        path: 'create-course',
        component: CreateCourse,
        meta: { requiresAdmin: true }
      },
      { path: 'order-data', component: OrderData },
      { path: 'transform-data', component: TransformData },
      { path: 'exp-course', component: ExpCourse },
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = "畅学订单系统"
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!store.getters.isAdmin) {
      next('/home'); // 非管理员用户重定向到主页
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
